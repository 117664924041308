import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Post from "../components/post"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  div:first-child {
    margin-bottom: 20px;
  }

  div {
    text-align: center;
    margin-bottom: 10px;
  }
`

const Quote = styled.div`
  font-style: italic;
  font-weight: 200;
  padding-bottom: 20px;
`

const About = ({ data, location }) => {
  return (
    <Layout location={location} title="Title">
      <Seo title="All posts" />
      <Content>
        <Wrapper>
          <StaticImage
            className="bio-avatar"
            layout="fixed"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../images/portrait.jpeg"
            width={200}
            height={200}
            quality={95}
            alt="Profile picture"
          />
          <Quote>Pursuing focus.</Quote>
          <div>
            My name is Phillip Michalowski and I'm a Software Engineer based out
            of Boston, MA.
          </div>
          <div>
            Some of my interests include jiu jitsu, music production, violin,
            photography, and writing.
          </div>
          <div>
            In my{" "}
            <a href="https://www.ezcater.com/company/about-us/" target="_blank">
              current work
            </a>
            , I help build web applications using React, Ruby on Rails, and
            Python.
          </div>
        </Wrapper>
      </Content>
    </Layout>
  )
}

export default About
